const Nopage = () => {
  const noPage = {
    'text-align': 'center',
    'font-weight': '700',
  };
  return (
    <div style={noPage}>
      <p>
        This page doesn&apos;t exist
        <br />
        {' '}
        Please check your URL or return to Our home page
      </p>
    </div>
  );
};

export default Nopage;
